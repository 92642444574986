import React from 'react'
import Helmet from 'react-helmet'
import config from '../../data/SiteConfig'
import styles from './index.module.scss'

import { Layout } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'

const MainLayout = ({children, activeSlug, activeSidebar}) => {
  return (
    <Layout className={styles.layout}>

      <Helmet>
        <meta name="description" content={config.siteDescription} />
      </Helmet>

      <Header active={{activeSlug, activeSidebar}} />

      <Layout>
        <Layout className={styles.siteLayout}>
          {children}
          <Footer/>
        </Layout>
      </Layout>

    </Layout>
  )
}

export default MainLayout
