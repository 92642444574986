import React from 'react'
import styles from './Footer.module.scss'
import config from '../../data/SiteConfig'

import { Layout, Typography } from 'antd'
const { Footer } = Layout
const { Text } = Typography

const F = () => (
  <Footer className={styles.footer}><Text type="secondary">{`${config.siteTitleShort} - ${config.copyright}`}</Text></Footer>
)

export default F
