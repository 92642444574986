import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import _ from 'lodash'
import config from '../../data/SiteConfig'
import styles from './Header.module.scss'

import { MenuFoldOutlined } from '@ant-design/icons'
import { Layout, Menu, Drawer, Button, Space } from 'antd'


const { Header: H } = Layout

const Header = ({active}) => {

  const { tags } = useStaticQuery(graphql`
    query {
      tags: allMarkdownRemark(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `)

  let openMenu = ''
  
  if (tags.group.filter(({fieldValue}) => fieldValue == active.activeSidebar).length > 0) {
    openMenu = `tag-${active.activeSidebar}`
  }

  const [visible, setVisible] = useState(false)

  return (
    <H className={styles.headerContainer}>
      <Space>
        <Button icon={<MenuFoldOutlined />} onClick={() => setVisible(true)} ghost/>
        <Link to="/" className={styles.headerLink}>{config.siteTitle}</Link>
      </Space>

      <Drawer placement="left" visible={visible} onClose={() => setVisible(false)} closable={false} bodyStyle={{ padding: 0 }} className={styles.sidebarContainer}>
        <Menu defaultSelectedKeys={[openMenu, active.activeSlug]} onClick={() => setVisible(false)} mode="inline">
          <Menu.ItemGroup title="Tags">
            {tags.group.map(({fieldValue}) => (
              <Menu.Item key={`tag-${fieldValue}`}>
                <Link  
                  to={`/tag/${_.kebabCase(fieldValue)}`}
                  key={fieldValue}>
                    {`#${fieldValue}`}
                </Link>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
          <Menu.ItemGroup title="En savoir plus">
            <Menu.Item key="/contribuer"><Link to="/contribuer">Contribuer</Link></Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      </Drawer>
    </H>
  )
}

export default Header
