const config = {
  siteTitle: 'Pandanwan Blog', // Site title.
  siteTitleShort: 'Pandawan', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Pandanwan Blog', // Alternative site title for SEO.
  siteLogo: 'logos/logo-1024.png', // Logo used for SEO and manifest.
  host: 'pandawan.taile.fr', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Blog sur le développement web (front & back) pour tous les futurs Jedi du web', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: 'XXX', // FB Application ID for using app insights
  googleAnalyticsID: 'XXX', // GA tracking ID.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD MMM YYYY', // Date format for display.
  userName: 'Tai Le', // Username to display in the author segment.
  userEmail: 'htjpascal@gmail.com', // Email used for RSS feed's author segment
  userGitHub: 'TaiRise', // Optionally renders "Follow Me" in the Bio segment.
  userLocation: 'Paris, France', // User location to display in the author segment.
  userAvatar: 'https://avatars3.githubusercontent.com/u/17015240?s=460&u=3a5597dca84d3eadea42c5433d80ab44847999b6&v=4', // User avatar to display in the author segment.
  userDescription:
    "Un Jedi utilise la Force pour la connaissance et la défense, jamais pour l'attaque.", // User description to display in the author segment.
  copyright: 'Copyright © 2020. All rights reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#001529', // Used for setting manifest and progress theme colors.
  backgroundColor: 'white', // Used for setting manifest background color.
  gutter: [{ xs: 16, sm: 16, md: 24, lg: 32 },{ xs: 16, sm: 16, md: 24, lg: 32 }],
}

// Validate

config.siteUrl = `https://${config.host}`

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
// if (config.siteRss && config.siteRss[0] !== "/")
//   config.siteRss = `/${config.siteRss}`;

module.exports = config
